import React, { useState, useEffect } from 'react';
import { Input, Label } from 'reactstrap';
import { DatePicker, DateRangePicker } from 'rsuite';
import moment from 'moment';

import 'rsuite/dist/rsuite.min.css';

import { useRecoilValue } from 'recoil';
import {
	EntityTypeHints,
	NormalizedProperty,
	OpenAPIDefinition,
	UpdateSource,
	ValueUpdatedCallback,
} from '../../../../../helpers/openapi/OpenAPITypes';
import { selectedAccountAtom } from '../../../../../helpers/atoms/AccountsAtom';
import ArrayContainer from './ArrayContainer';
import MapContainer from './MapContainer';
import ObjectContainer from './ObjectContainer';
import PrimitiveObjectContainer from './PrimitiveObjectContainer';
import { EntityData } from '../../../../../types';

import './ModelProperty.scss';
import { DxToggle } from 'genesys-react-components';

interface IProps {
	property: NormalizedProperty;
	onValueUpdated?: ValueUpdatedCallback;
	arrayIndex?: number;
	initialValue?: any;
	swagger: OpenAPIDefinition;
	oauthHeader?: boolean;
	isStringToObjectMap?: boolean;
	dynamicEntityType?: EntityTypeHints;
	arrayEntityType?: EntityTypeHints;
	searchFields?: string[];
	updateSource?: UpdateSource;
}

export default function ModelProperty(props: IProps) {
	const selectedAccount = useRecoilValue(selectedAccountAtom);

	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [isLoadingEntities, setIsLoadingEntities] = useState<boolean>(false);
	const [value, setValue] = useState<any>(props.initialValue);
	const [entities, setEntities] = useState<EntityData[]>();
	const [dynamicEntityType, setDynamicEntityType] = useState<EntityTypeHints | undefined>();

	useEffect(() => {
		if (props.initialValue !== value) {
			setValue(props.initialValue);
		}
		if (props.property) {
			const explicitEntityType: EntityTypeHints | undefined =
				props.property.schema['x-genesys-entity-type']?.value || props.arrayEntityType;
			const entityType: EntityTypeHints | undefined = props.dynamicEntityType || explicitEntityType;
			if (entityType) {
				getEntities(entityType);
			}
		}
	}, [props.initialValue, props.property, props.dynamicEntityType]); // eslint-disable-line react-hooks/exhaustive-deps

	// reset the value property when matching dimension property changes to a non-entity type
	useEffect(() => {
		if (props.dynamicEntityType === EntityTypeHints.DIMENSION_SELECTOR && dynamicEntityType) {
			// this update is triggered by change to dimension property, so wait for that update to flush before resetting matching value property
			setTimeout(() => {
				raiseUpdate(props.property.propertyName, undefined, props.arrayIndex);
			}, 300);
			if (isLoadingEntities) setIsLoadingEntities(false);
		}
		if (props.dynamicEntityType && props.dynamicEntityType !== dynamicEntityType) {
			setDynamicEntityType(props.dynamicEntityType);
		}
	}, [props.dynamicEntityType]); // eslint-disable-line react-hooks/exhaustive-deps

	async function getEntities(entityType: EntityTypeHints | undefined) {
		const shouldSetTrue: boolean =
			!!selectedAccount &&
			selectedAccount.isLoading &&
			entityType !== EntityTypeHints.DIMENSION_SELECTOR &&
			entityType !== EntityTypeHints.DIMENSION_TYPE &&
			entityType !== EntityTypeHints.MEDIA_TYPE_ID &&
			!isLoadingEntities;
		const shouldSetFalse: boolean = !!selectedAccount && !selectedAccount.isLoading && isLoadingEntities;

		if (shouldSetTrue) {
			setIsLoadingEntities(true);
		} else if (shouldSetFalse) {
			setIsLoadingEntities(false);
		}

		switch (entityType) {
			case EntityTypeHints.USER_ID:
				if (!selectedAccount) return;
				setEntities(selectedAccount.userCache || []);
				return;
			case EntityTypeHints.DIVISION_ID:
				if (!selectedAccount) return;
				setEntities(selectedAccount.divisionCache || []);
				return;
			case EntityTypeHints.ORG_PRESENCE_ID:
				if (!selectedAccount) return;
				setEntities(selectedAccount.orgPresenceCache || []);
				return;
			case EntityTypeHints.QUEUE_ID:
				if (!selectedAccount) return;
				setEntities(selectedAccount.queueCache || []);
				return;
			case EntityTypeHints.SYSTEM_PRESENCE_ID:
				if (!selectedAccount) return;
				setEntities(selectedAccount.systemPresenceCache || []);
				return;
			case EntityTypeHints.MEDIA_TYPE_ID:
				const mediaTypes: EntityData[] = ['callback', 'chat', 'cobrowse', 'email', 'message', 'screenshare', 'voice'].map(
					(mediaType: string) => {
						return { id: mediaType, name: mediaType };
					}
				);
				setEntities(mediaTypes);
				return;
			default:
				setEntities([]);
				return;
		}
	}

	// getPrimitiveComponent is where the magic happens. Returns the editor component based on the property's type and format
	function getPrimitiveComponent(p: NormalizedProperty, inputId: string, descriptionId: string) {
		const schemaType = p.schema.type?.toLowerCase();

		/**
		 * The following conditions are ordered by most specific and important first, least specific and fallback/default
		 * components last. Each true condition should return a reponsive editor component that fills its space, reports its
		 * value changes, responds to having its value changed, and follows accessibility standards.
		 */

		// Array
		if (schemaType === 'array') {
			// Special case: type is not a primitive, so don't return a component
			return;
		}

		// Object
		if (schemaType === 'object') {
			// Primitive object
			if (p.schema.__isPrimitiveObject) {
				return (
					<PrimitiveObjectContainer
						initialValue={value}
						inputId={inputId}
						descriptionId={descriptionId}
						schemaType={schemaType}
						p={p}
						onFocus={setIsFocused}
						onStringUpdate={stringValueUpdated}
						onBooleanUpdate={booleanValueUpdated}
						onNumberUpdate={integerValueUpdated}
						onObjectUpdate={valueUpdated}
						onArrayUpdate={valueUpdated}
						oauthHeader={props.oauthHeader}
						updateSource={props.updateSource}
						swagger={props.swagger}
						isStringToObjectMap={props.isStringToObjectMap}
					/>
				);
			}

			// Special case: type is not a primitive, so don't return a component
			return;
		}

		// Numeric
		if (schemaType === 'integer' || schemaType === 'number') {
			let onChange;
			if (schemaType === 'integer') {
				onChange = (e: React.ChangeEvent<HTMLInputElement>) => integerValueUpdated(p.propertyName, parseInt(e.target.value));
			} else {
				onChange = (e: React.ChangeEvent<HTMLInputElement>) => integerValueUpdated(p.propertyName, parseFloat(e.target.value));
			}
			return (
				<Input
					id={inputId}
					aria-describedby={descriptionId}
					type={schemaType as any}
					className="value"
					placeholder={`${p.propertyName} (${p.typeDisplay})`}
					autoComplete="off"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={onChange}
					value={value !== undefined ? value : ''}
				/>
			);
		}
		// Boolean
		if (schemaType === 'boolean') {
			/*
			 * the ternary prevents an infinite toggle loop.
			 * If the update came from the JSON editor, update the matching wizard editor value.
			 * If the update came from the wizard, ignore the value change from props because it has already been applied.
			 */
			return props.updateSource === UpdateSource.JSON ? (
				<DxToggle isTriState={true} value={value} onChange={(value) => booleanValueUpdated(p.propertyName, value)} />
			) : (
				<DxToggle isTriState={true} initialValue={value} onChange={(value) => booleanValueUpdated(p.propertyName, value)} />
			);
		}

		// Formatted strings
		if (schemaType === 'string') {
			// Enum
			if (p.schema.enum) {
				return (
					<Input
						id={inputId}
						aria-describedby={descriptionId}
						type="select"
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						onChange={(e) => stringValueUpdated(p.propertyName, e.target.value)}
						value={value || ''}
					>
						<option></option>
						{p.schema.enum.map((val) => (
							<option key={val}>{val}</option>
						))}
					</Input>
				);
			}

			//Date
			if (p.schema.format === 'date') {
				let dateVal: Date | undefined;
				if (value) dateVal = moment(value).toDate();

				return (
					<DatePicker
						format="yyyy-MM-dd"
						showMeridian={true}
						onChange={(date) => {
							if (date) stringValueUpdated(p.propertyName, moment(date).format('YYYY-MM-DD'));
							else {
								dateVal = undefined;
								stringValueUpdated(p.propertyName, '');
							}
						}}
						value={dateVal && !isNaN(dateVal.getTime()) ? dateVal : null}
					/>
				);
			}

			// Datetime
			if (p.schema.format === 'date-time') {
				let dateVal: Date | undefined;
				if (value) dateVal = new Date(value);
				return (
					<DatePicker
						preventOverflow={true}
						format="dd MMM yyyy hh:mm:ss aa"
						showMeridian={true}
						onChange={(date) => {
							if (date) stringValueUpdated(p.propertyName, date?.toISOString());
							else {
								dateVal = undefined;
								stringValueUpdated(p.propertyName, '');
							}
						}}
						value={dateVal && !isNaN(dateVal.getTime()) ? dateVal : null}
					/>
				);
			}

			//handle intervals
			if (p.schema.format === 'interval') {
				let dates: [Date, Date] | undefined;
				if (value) {
					const timeValues = value.split('/');
					if (timeValues.length > 1) {
						//create date object
						dates = [new Date(timeValues[0]), new Date(timeValues[1])];
					}
				}

				return (
					<DateRangePicker
						format="dd MMM yyyy hh:mm:ss aa"
						showMeridian={true}
						onChange={(date) => {
							if (date) stringValueUpdated(p.propertyName, `${date[0].toISOString()}/${date[1].toISOString()}`);
							else {
								dates = undefined;
								stringValueUpdated(p.propertyName, '');
							}
						}}
						value={dates && !dates.some((date) => isNaN(date.getTime())) ? dates : null}
					/>
				);
			}
		}

		const placeholder: string = props.oauthHeader ? 'Access Token' : p.propertyName;
		if ((entities && entities.length > 0 && p.schema.type !== 'array') || (isLoadingEntities && p.schema.type !== 'array')) {
			return isLoadingEntities ? (
				<div className="spinner"></div>
			) : (
				<Input
					id={inputId}
					aria-describedby={descriptionId}
					type="select"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={(e) => stringValueUpdated(p.propertyName, e.target.value)}
					value={value || ''}
				>
					<option></option>
					{(entities || []).map((val: EntityData) => (
						<option value={val.id} key={val.id}>
							{val.name}
						</option>
					))}
				</Input>
			);
		}

		if (props.searchFields && props.searchFields.length) {
			return (
				<Input
					id={inputId}
					aria-describedby={descriptionId}
					type="select"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={(e) => stringValueUpdated(p.propertyName, e.target.value)}
					value={value || ''}
				>
					<option></option>
					{props.searchFields.map((val: string) => (
						<option key={val}>{val}</option>
					))}
				</Input>
			);
		}

		// DEFAULT: unformatted string
		return props.oauthHeader ? (
			<div>
				<span>Bearer &#123; &nbsp;</span>
				<Input
					id={inputId}
					aria-describedby={descriptionId}
					type={props.oauthHeader ? 'password' : 'text'}
					className="value"
					placeholder={`${placeholder} (${p.typeDisplay})`}
					autoComplete="off"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={(e) => stringValueUpdated(p.propertyName, e.target.value)}
					value={value || ''}
				/>
				<span>&nbsp; &#125;</span>
			</div>
		) : (
			<Input
				id={inputId}
				aria-describedby={descriptionId}
				type={props.oauthHeader ? 'password' : 'text'}
				className="value"
				placeholder={`${p.propertyName} (${p.typeDisplay})`}
				autoComplete="off"
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				onChange={(e) => stringValueUpdated(p.propertyName, e.target.value)}
				value={value || ''}
			/>
		);
	}

	function integerValueUpdated(propertyName: string, val: any) {
		if (val === null || isNaN(val)) val = undefined;
		raiseUpdate(propertyName, val, props.arrayIndex);
	}

	function stringValueUpdated(propertyName: string, val: any) {
		if (val === '' || val === null) val = undefined;
		const falseUpdate = !value?.length && !val?.length;
		if (falseUpdate) return;
		raiseUpdate(propertyName, val, props.arrayIndex);
	}

	function booleanValueUpdated(propertyName: string, val?: boolean) {
		if (value === val) return;
		raiseUpdate(propertyName, val, props.arrayIndex);
	}

	function valueUpdated(propertyName: string, val: any) {
		raiseUpdate(propertyName, val, props.arrayIndex);
	}

	function raiseUpdate(propertyName: string, val: any, arrayIndex?: number) {
		setValue(val);
		if (props.onValueUpdated) props.onValueUpdated(propertyName, val, arrayIndex);
	}

	function getContainerComponent(p: NormalizedProperty, inputId: string, descriptionId: string) {
		switch (p.schema.type) {
			case 'object': {
				if (p.schema.additionalProperties || props.isStringToObjectMap) {
					return (
						<MapContainer
							property={p}
							onValueUpdated={valueUpdated}
							initialValue={props.initialValue}
							swagger={props.swagger}
							isNestedMapContainer={props.isStringToObjectMap}
							updateSource={props.updateSource}
						/>
					);
				} else {
					return (
						<ObjectContainer
							property={p}
							onValueUpdated={valueUpdated}
							initialValue={props.initialValue}
							swagger={props.swagger}
							updateSource={props.updateSource}
						/>
					);
				}
			}
			case 'array': {
				return (
					<ArrayContainer
						property={p}
						onValueUpdated={valueUpdated}
						initialValue={props.initialValue}
						swagger={props.swagger}
						updateSource={props.updateSource}
					/>
				);
			}
			default: {
				if (p.schema.__isRecursive) {
					// console.warn('RECURSIVE TYPE', p);
					return <div>RECURSIVE TYPE ({p.schema.__modelName})</div>;
				}
				console.warn('UNKNOWN CONTAINER', p);
				return <div>UNKNOWN CONTAINER</div>;
			}
		}
	}

	const p = props.property;
	const inputId = p.id;
	const labelId = `${p.id}-label`;
	const descriptionId = `${p.id}-description`;

	// Render as a primitive property
	let component;
	let nestedMapValueComponent;
	let containerComponent;

	component = getPrimitiveComponent(p, inputId, descriptionId);
	if (!component) {
		containerComponent = getContainerComponent(p, inputId, descriptionId);
	}

	// Render label
	let label;
	if (p.propertyName) {
		label = (
			<Label id={labelId} for={inputId} className={`label${isFocused ? ' focused' : ''}`}>
				<span className="property-name">{p.propertyName}</span>
				<span className="property-type">
					{p.typeDisplay}
					{p.schema.__isRequired && ', required'}
				</span>
			</Label>
		);
	}

	if (p.schema.readOnly) {
		//schema is readonly, so don't return property
		return <React.Fragment> </React.Fragment>;
	}
	return (
		<div className="model-property-container">
			{label}
			{component}
			{nestedMapValueComponent}
			<span id={descriptionId} className="description">
				{p.schema.description}
			</span>
			{containerComponent}
		</div>
	);
}
