import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import LayoutController from './components/LayoutController';
import HistoryAccess from './components/historyaccess/HistoryAccess';
import LoadingPlaceholder from './components/loadingplaceholder/LoadingPlaceholder';
import PrivacyBanner from './components/privacybanner/PrivacyBanner';

export default function App() {
	return (
		<RecoilRoot>
			<RecoilNexus />
			{/* This loading fallback is triggered by asynchronously initialized atoms */}
			<Suspense fallback={<LoadingPlaceholder />}>
				<BrowserRouter>
					<HistoryAccess />
					<LayoutController />
					<PrivacyBanner />
				</BrowserRouter>
			</Suspense>
		</RecoilRoot>
	);
}
