import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React, { useState } from 'react';
import { ModelSchema, OpenAPIDefinition } from '../../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';

import './ModelSchemaDisplay.scss';

interface IProps {
	// Which name is set controls how the heading displays (root model vs property)
	modelName?: string;
	propertyName?: string;

	schema: ModelSchema;
	isRequest?: boolean;
	definition: OpenAPIDefinition;
	showExpanded?: boolean;
	indent?: number;
	className?: string;
}

export default function ModelSchemaDisplay(props: IProps) {
	const [isExpanded, setIsExpanded] = useState(props.showExpanded === true);

	// Resolve $ref if it is one
	let model = { ...props.schema };
	if (props.schema.$ref)
		SwaggerCache.applyModelSchema(model, SwaggerCache.getModel(props.definition, props.schema.$ref) || ({} as ModelSchema));
	if (!model) {
		return (
			<div>
				FAILED TO FIND MODEL <code>{JSON.stringify(props.schema)}</code>
			</div>
		);
	}

	// Build title -- h5 for root models, as a property otherwise
	let title;
	if (props.modelName) {
		title = <h5>{model.type === 'array' ? 'Array of:' : props.modelName}</h5>;
	} else if (props.propertyName) {
		title = displayProperty(props.propertyName, model);
	}

	// Wrap with collapser
	if (title) {
		title = (
			<div className={`title-container${props.modelName ? ' model-name-title' : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
				<GenesysDevIcon className="chevron" icon={isExpanded ? GenesysDevIcons.AppChevronDown : GenesysDevIcons.AppChevronRight} />
				{title}
			</div>
		);
	}

	// Render properties
	let propertyDisplay;
	if (isExpanded) {
		propertyDisplay = (
			<div className={`modelSchemaContainer${!props.indent || props.indent === 0 ? ' first-level' : ''}`} key={model.__modelName}>
				{renderProperties(model, props)}
			</div>
		);
	}

	return (
		<div className={`model-schema${props.className ? ' ' + props.className : ''}`}>
			{title}
			{propertyDisplay}
		</div>
	);
}

function renderProperties(model: ModelSchema, props: IProps) {
	const properties = [] as JSX.Element[];

	// Check for special base types
	if (!props.modelName && model && model.type === 'array' && model.items) {
		// Root level array -- unique case since there's no property or type name
		if (model.items.$ref || model.items.type === 'object') {
			// Display property with model schema
			properties.push(
				<ModelSchemaDisplay
					propertyName="array of"
					schema={model.items}
					isRequest={props.isRequest}
					definition={props.definition}
					showExpanded={true}
					indent={(props.indent || 0) + 1}
					key={makeKey(model)}
				/>
			);
		} else {
			// Items is a primitive type
			properties.push(displayProperty('array of', model.items));
		}
	} else if (model.type === 'object') {
		// Type: object
		// Iterate properties
		for (let [key, property] of Object.entries(model.properties || {})) {
			// Suppress readonly properties for request models
			if (!property.readOnly || !props.isRequest) {
				// Determine property type
				if (property.additionalProperties?.$ref) {
					// Display map
					// Copy some stuff to the items schema so it knows where it came from
					property.additionalProperties.__isMap = true;
					property.additionalProperties.description = property.description || property.additionalProperties.description;

					properties.push(
						<ModelSchemaDisplay
							propertyName={key}
							schema={property.additionalProperties}
							isRequest={props.isRequest}
							definition={props.definition}
							showExpanded={false}
							indent={(props.indent || 0) + 1}
							key={makeKey(property)}
						/>
					);
				} else if (property.$ref || property.type === 'object') {
					// Display collapsed model schema
					properties.push(
						<ModelSchemaDisplay
							propertyName={key}
							schema={property}
							isRequest={props.isRequest}
							definition={props.definition}
							showExpanded={false}
							indent={(props.indent || 0) + 1}
							key={makeKey(property)}
						/>
					);
				} else if (property.type === 'array' && property.items && (property.items.$ref || property.items.type === 'object')) {
					// Display array of model
					// Copy some stuff to the items schema so it knows where it came from
					property.items.__isArrayMember = true;
					property.items.description = property.description || property.items.description;

					properties.push(
						<ModelSchemaDisplay
							propertyName={key}
							schema={property.items}
							isRequest={props.isRequest}
							definition={props.definition}
							showExpanded={false}
							indent={(props.indent || 0) + 1}
							key={makeKey(property)}
						/>
					);
				} else {
					// Primitive non-container property
					properties.push(displayProperty(key, property));
				}
			}
		}
	} else {
		// Type: unstructured primitive (e.g. string, integer)
		properties.push(displayProperty('(unstructured primitive)', props.schema));
	}

	return properties;
}

function displayProperty(name: string, property: ModelSchema) {
	// Prepare property type name
	let propertyType = property.items ? property.items.__modelName || property.items.type : property.__modelName || property.type;
	if (property.type === 'array' || property.__isArrayMember) propertyType += '[]';
	if (property.type === 'object' && property.additionalProperties) {
		let mapOf = 'any';

		if (property.additionalProperties.type) {
			if (property.additionalProperties.type === 'array' && property.additionalProperties.items?.type) {
				// Map of: array of primitive
				mapOf = property.additionalProperties.items.type + '[]';
			} else if (property.additionalProperties.type === 'array' && property.additionalProperties.$ref) {
				// Map of: array of model
				mapOf = SwaggerCache.getModelNameFromRef(property.additionalProperties.$ref) || property.additionalProperties.$ref;
			} else {
				// Map of: primitive -- show format clarification if specified (e.g. int32 vs integer)
				mapOf = property.additionalProperties.format || property.additionalProperties.type;
			}
		} else if (property.additionalProperties.$ref) {
			// Map of model
			mapOf = SwaggerCache.getModelNameFromRef(property.additionalProperties.$ref) || property.additionalProperties.$ref;
		}

		propertyType = `Map<string, ${mapOf}>`;
	}
	if (property.__isMap) {
		// Something forced this to be displayed as a map (probably a map of model property since the type doesn't know it's the value type of a map)
		propertyType = `Map<string, ${property.__modelName || 'unknown'}>`;
	}

	return (
		<div className="property-container" key={makeKey(property)}>
			<div className="property-name">{name}</div>
			<div className="property-description">
				({propertyType}
				{property.__isRequired === true ? <em> required</em> : undefined}){' '}
				{property.description ? property.description.replace(/\.$/, '') + '.' : ''}
				{property.enum || (property.items && property.items.enum) ? (
					<span>
						<em>Valid values:</em> {property.enum ? property.enum.join(', ') : property.items?.enum?.join(', ')}.
					</span>
				) : (
					''
				)}
			</div>
		</div>
	);
}

// Standard format to make unique keys for react's purposes
function makeKey(schema: ModelSchema) {
	const key = '' + schema.__propertyName + schema.__modelName + schema.type;
	return key;
}
